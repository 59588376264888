var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"themev2"},[_c('br'),_c('div',{staticClass:"logo-centered"}),_c('div',{staticClass:"w560"},[_c('div',{staticClass:"w300"},[_c('h1',[_vm._v("Create new account")]),_c('p',[_vm._v("Fill in your details below "+_vm._s(_vm.$route.query.invite ? 'to accept the invitation' : 'and an administrator will review and activate your account')+":")])]),_c('ValidationObserver',{staticClass:"w300",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",class:{disabled: _vm.$store.getters.loading},attrs:{"method":"POST","spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"firstName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstName"}},[_vm._v("First name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],class:classes,attrs:{"name":"firstName","type":"text","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"lastName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],class:classes,attrs:{"name":"lastName","type":"text","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"email","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Organizational Email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.email),expression:"form.email",modifiers:{"trim":true}}],class:classes,attrs:{"name":"email","type":"email","novalidate":"","disabled":_vm.$store.getters.loading || _vm.$route.query.invite},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"role","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Role")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.role),expression:"form.role"}],staticClass:"select-css",class:classes,staticStyle:{"height":"30px"},attrs:{"disabled":_vm.$store.getters.loading || _vm.$route.query.invite},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Student","selected":""}},[_vm._v("Student")]),_c('option',{attrs:{"value":"Professor"}},[_vm._v("Faculty")]),_c('option',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.invite),expression:"$route.query.invite"}],attrs:{"value":"OrgAdmin"}},[_vm._v("Organization Admin")]),_c('option',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.invite && _vm.form.role == 'Admin'),expression:"$route.query.invite && form.role == 'Admin'"}],attrs:{"value":"Admin","disabled":""}},[_vm._v("Admin")])]),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"timeZoneValidator",staticClass:"inputrow",attrs:{"name":"timezone","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"timezone"}},[_vm._v("Time zone")]),_c('vSelect',{class:classes,staticStyle:{"width":"100%","clear":"both"},attrs:{"disabled":_vm.$store.getters.loading,"placeholder":"Select preferred time zone","name":"timezone","options":_vm.timeZones,"clearable":false,"reduce":function (tz) { return tz.id; },"label":"id"},on:{"close":_vm.validateSelectedTimeZone},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false))]}},{key:"option",fn:function(tz){return [_vm._v(" "+_vm._s(tz.id)+" (GMT"+_vm._s(tz.offset >= 0 ? '+':'')+_vm._s(tz.offset)+") ")]}},{key:"selected-option",fn:function(tz){return [_vm._v(" "+_vm._s(tz.id)+" (GMT"+_vm._s(tz.offset >= 0 ? '+':'')+_vm._s(tz.offset)+") ")]}}],null,true),model:{value:(_vm.form.timeZone),callback:function ($$v) {_vm.$set(_vm.form, "timeZone", $$v)},expression:"form.timeZone"}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"password",staticClass:"inputrow",attrs:{"name":"password","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],class:classes,attrs:{"name":"password","type":"password","novalidate":"","placeholder":"At least 8 characters and 1 number","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"password2","rules":"required|confirmed:password","tag":"div","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password2"}},[_vm._v("Repeat password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password2),expression:"form.password2"}],class:classes,attrs:{"name":"password2","type":"password","novalidate":"","placeholder":"At least 8 characters and 1 number","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.password2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password2", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"phone","rules":"phone","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone number "),_c('span',{staticStyle:{"font-size":"10px","color":"grey","padding-left":"5px","vertical-align":"bottom"}},[_vm._v("(optional)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],class:classes,attrs:{"name":"phone","type":"tel","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"full",class:{disabled: invalid || _vm.$store.getters.loading},attrs:{"type":"submit","disabled":invalid || _vm.$store.getters.loading}},[_vm._v("Register")]),(_vm.$store.getters.loading)?_c('span',{staticClass:"spinner"}):_vm._e(),(_vm.$store.state.auth.error)?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.$store.state.auth.error))]):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }